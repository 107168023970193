import React, { useEffect, useState } from "react";
import API from "../../utils/API";

import {
  Box,
  Collapse,
  Container,
  DialogTitle,
  IconButton,
  Dialog,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { CheckBox } from "@mui/icons-material";
function prepareDate(date) {
  const newDate = new Date(date);
  const dateFormat =
    newDate.getUTCFullYear() +
    "-" +
    ("00" + (newDate.getUTCMonth() + 1)).slice(-2) +
    "-" +
    ("00" + newDate.getUTCDate()).slice(-2) +
    " " +
    ("00" + newDate.getUTCHours()).slice(-2) +
    ":" +
    ("00" + newDate.getUTCMinutes()).slice(-2) +
    ":" +
    ("00" + newDate.getUTCSeconds()).slice(-2);
  return dateFormat;
}
const CohortCell = ({ day, offer, name, type, width }) => {
  const [loader, setLoader] = useState(false);
  const [result, setResult] = useState(null);

  useEffect(() => {
    setLoader(true);
    API.get(
      `/cohort?offer=${offer}&source=${name}&type=${type}&d1=${day}`
    ).then((res) => {
      setResult(res.data);
      setLoader(false);
    });
  }, []);
  return (
    <TableCell width={width}>
      {loader ? (
        <CircularProgress
          size={20}
          color={
            type === "0d" ? "secondary" : type === "7d" ? "success" : "inherit"
          }
        />
      ) : (
        result
      )}
    </TableCell>
  );
};

const SourceRow = ({ name, data, offer, cellWidth }) => {
  const [open, setOpen] = useState(false);
  const filters = JSON.parse(localStorage.getItem("filters"));

  const [total, setTotal] = useState({
    install: 0,
    click: 0,
    purchase: 0,
    registration: 0,
    trial: 0,
    custom: 0,
    cr: 0,
    revenue: 0,
  });
  useEffect(() => {
    const temp = {
      install: 0,
      purchase: 0,
      click: 0,
      registration: 0,
      trial: 0,
      custom: 0,
      cr: 0,
      revenue: 0,
    };
    const keys = Object.keys(data);
    for (let key of keys) {
      temp.install = temp.install + data[key].install;
      temp.purchase = temp.purchase + data[key].purchase;
      temp.click = temp.click + data[key].click;
      temp.trial = temp.trial + data[key].trial;
      temp.registration = temp.registration + data[key].registration;
      temp.custom = temp.custom + data[key].custom;
      temp.revenue = temp.revenue + data[key].revenue;
    }
    temp.cr = Math.floor((temp.purchase / temp.install) * 10000) / 100;
    setTotal(temp);
  }, [data]);
  return (
    <>
      <TableRow>
        <TableCell
          width={
            (100 /
              (JSON.parse(localStorage.getItem("filters")).filter((item) => {
                return item === true ? 1 : 0;
              }).length +
                2)) *
              2 +
            "%"
          }
          style={{ paddingLeft: "20px" }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {name}
        </TableCell>
        {filters[10] ? (
          <TableCell width={cellWidth}>{total.click}</TableCell>
        ) : null}
        {filters[0] ? (
          <TableCell width={cellWidth}>{total.install}</TableCell>
        ) : null}
        {filters[1] ? (
          <TableCell width={cellWidth}>{total.purchase}</TableCell>
        ) : null}
        {filters[2] ? (
          <TableCell width={cellWidth}>{total.registration}</TableCell>
        ) : null}
        {filters[3] ? (
          <TableCell width={cellWidth}>{total.trial}</TableCell>
        ) : null}
        {filters[4] ? (
          <TableCell width={cellWidth}>{total.custom}</TableCell>
        ) : null}
        {filters[5] ? (
          <TableCell width={cellWidth}>{total.cr}%</TableCell>
        ) : null}
        {filters[9] ? (
          <TableCell width={cellWidth}>{total.revenue / 100 || 0}$</TableCell>
        ) : null}
        {filters[6] ? <TableCell width={cellWidth}></TableCell> : null}
        {filters[7] ? <TableCell width={cellWidth}></TableCell> : null}
        {filters[8] ? <TableCell width={cellWidth}></TableCell> : null}
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={12}>
          <Collapse in={open} unmountOnExit>
            <Box sx={{ margin: 0 }}>
              <Table size="small">
                <TableBody>
                  {Object.keys(data).map((item, index) => {
                    return (
                      <TableRow
                        style={{
                          background: index % 2 === 0 ? "#464646" : "#312b2b",
                        }}
                      >
                        <TableCell
                          width={
                            (100 /
                              (JSON.parse(
                                localStorage.getItem("filters")
                              ).filter((item) => {
                                return item === true ? 1 : 0;
                              }).length +
                                2)) *
                              2 +
                            "%"
                          }
                          style={{ paddingLeft: "40px" }}
                        >
                          {item}
                        </TableCell>
                        {filters[10] ? (
                          <TableCell width={cellWidth}>
                            {data[item].click}
                          </TableCell>
                        ) : null}
                        {filters[0] ? (
                          <TableCell width={cellWidth}>
                            {data[item].install}
                          </TableCell>
                        ) : null}
                        {filters[1] ? (
                          <TableCell width={cellWidth}>
                            {data[item].purchase}
                          </TableCell>
                        ) : null}
                        {filters[2] ? (
                          <TableCell width={cellWidth}>
                            {data[item].registration}
                          </TableCell>
                        ) : null}
                        {filters[3] ? (
                          <TableCell width={cellWidth}>
                            {data[item].trial}
                          </TableCell>
                        ) : null}
                        {filters[4] ? (
                          <TableCell width={cellWidth}>
                            {data[item].custom}
                          </TableCell>
                        ) : null}
                        {filters[5] ? (
                          <TableCell width={cellWidth}>
                            {data[item].cr}%
                          </TableCell>
                        ) : null}
                        {filters[9] ? (
                          <TableCell width={cellWidth}>
                            {data[item].revenue / 100 || 0}$
                          </TableCell>
                        ) : null}
                        {filters[6] ? (
                          <CohortCell
                            width={cellWidth}
                            day={item}
                            name={name}
                            type="0d"
                            offer={offer}
                          />
                        ) : null}
                        {filters[7] ? (
                          <CohortCell
                            width={cellWidth}
                            day={item}
                            name={name}
                            type="7d"
                            offer={offer}
                          />
                        ) : null}
                        {filters[8] ? (
                          <CohortCell
                            width={cellWidth}
                            day={item}
                            name={name}
                            type="30d"
                            offer={offer}
                          />
                        ) : null}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
const OfferRow = ({ data, date, cellWidth }) => {
  const [open, setOpen] = useState(false);
  const [offerData, setOfferData] = useState([]);
  const filters = JSON.parse(localStorage.getItem("filters"));
  const [total, setTotal] = useState({
    installs: 0,
    trial: 0,
    purchase: 0,
    custom: 0,
    registration: 0,
    revenue: 0,
    click: 0,
  });
  function loadSources() {
    API.get(`/offerstats?offer=${data.offer}&d1=${date[0]}&d2=${date[1]}`).then(
      (res) => {
        const { data } = res;
        setOfferData(data);
        const temp = {
          install: 0,
          trial: 0,
          click: 0,
          purchase: 0,
          custom: 0,
          registration: 0,
          cr: 0,
          revenue: 0,
        };
        for (let k in data) {
          for (let j in data[k]) {
            temp.install = temp.install + data[k][j].install;
            temp.purchase = temp.purchase + data[k][j].purchase;
            temp.registration = temp.registration + data[k][j].registration;
            temp.trial = temp.trial + data[k][j].trial;
            temp.custom = temp.custom + data[k][j].custom;
            temp.revenue = temp.revenue + data[k][j].revenue;
            temp.click = temp.click + data[k][j].click;
          }
        }
        temp.cr = Math.floor((temp.purchase / temp.install) * 10000) / 100;
        setTotal(temp);
      }
    );
  }
  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "none" } }}>
        <TableCell
          width={
            (100 /
              (JSON.parse(localStorage.getItem("filters")).filter((item) => {
                return item === true ? 1 : 0;
              }).length +
                2)) *
              2 +
            "%"
          }
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setOpen(!open);
              if (!open && !offerData.length) {
                loadSources();
              }
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {data.offer}
        </TableCell>
        {filters[10] ? (
          <TableCell width={cellWidth}>{total.click}</TableCell>
        ) : null}
        {filters[0] ? (
          <TableCell width={cellWidth}>{total.install}</TableCell>
        ) : null}
        {filters[1] ? (
          <TableCell width={cellWidth}>{total.purchase}</TableCell>
        ) : null}
        {filters[2] ? (
          <TableCell width={cellWidth}>{total.registration}</TableCell>
        ) : null}
        {filters[3] ? (
          <TableCell width={cellWidth}>{total.trial}</TableCell>
        ) : null}
        {filters[4] ? (
          <TableCell width={cellWidth}>{total.custom}</TableCell>
        ) : null}
        {filters[5] ? (
          <TableCell width={cellWidth}>{total.cr}%</TableCell>
        ) : null}
        {filters[9] ? (
          <TableCell width={cellWidth}>{total.revenue / 100 || 0}$</TableCell>
        ) : null}
        {filters[6] ? <TableCell width={cellWidth}></TableCell> : null}
        {filters[7] ? <TableCell width={cellWidth}></TableCell> : null}
        {filters[8] ? <TableCell width={cellWidth}></TableCell> : null}
        {/* <TableCell>{data.events}</TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0, margin: 0 }} colSpan={12}>
          <Collapse in={open} unmountOnExit>
            <Table size="small">
              <TableBody>
                {Object.keys(offerData).map((item) => {
                  return (
                    <SourceRow
                      cellWidth={cellWidth}
                      offer={data.offer}
                      name={item}
                      data={offerData[item]}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
const FilterDialog = ({ open, close }) => {
  const [status, setStatus] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  function handleChange(number) {
    const prev = [...status];
    prev[number] = !prev[number];
    setStatus(prev);
  }
  useEffect(() => {
    const filters = localStorage.getItem("filters");
    if (filters) {
      setStatus(JSON.parse(filters));
    }
  }, []);
  function handleSave() {
    localStorage.setItem("filters", JSON.stringify(status));
    close();
  }
  return (
    <Dialog open={open} onClose={() => close()}>
      <DialogTitle>Настройка колонок</DialogTitle>
      <DialogContent>
        <FormControlLabel
          onChange={() => handleChange(10)}
          checked={status[10]}
          control={<Checkbox />}
          label="Click"
        />
        <FormControlLabel
          onChange={() => handleChange(0)}
          checked={status[0]}
          control={<Checkbox />}
          label="Установки"
        />
        <FormControlLabel
          onChange={() => handleChange(1)}
          checked={status[1]}
          control={<Checkbox />}
          label="Покупки"
        />
        <FormControlLabel
          onChange={() => handleChange(2)}
          checked={status[2]}
          control={<Checkbox />}
          label="Регистрации"
        />
        <FormControlLabel
          onChange={() => handleChange(3)}
          checked={status[3]}
          control={<Checkbox />}
          label="Триалы"
        />
        <FormControlLabel
          onChange={() => handleChange(4)}
          checked={status[4]}
          control={<Checkbox />}
          label="Кастом"
        />
        <FormControlLabel
          onChange={() => handleChange(5)}
          checked={status[5]}
          control={<Checkbox />}
          label="CR"
        />
        <FormControlLabel
          onChange={() => handleChange(9)}
          checked={status[9]}
          control={<Checkbox />}
          label="Revenue"
        />
        <FormControlLabel
          onChange={() => handleChange(6)}
          checked={status[6]}
          control={<Checkbox />}
          label="0d"
        />
        <FormControlLabel
          onChange={() => handleChange(7)}
          checked={status[7]}
          control={<Checkbox />}
          label="7d"
        />
        <FormControlLabel
          onChange={() => handleChange(8)}
          checked={status[8]}
          control={<Checkbox />}
          label="30d"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleSave()}>Сохранить</Button>
      </DialogActions>
    </Dialog>
  );
};
const Main = () => {
  const [offers, setOffers] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [date, setDate] = useState([new Date(), new Date()]);

  const filters = JSON.parse(localStorage.getItem("filters"));
  const [group, setGroup] = useState("day");
  useEffect(() => {
    const d1 = date[0];
    d1.setHours(0);
    d1.setMinutes(0);

    API.get(`/offers?d1=${d1}&d2=${date[1]}`).then((res) => {
      setOffers(res.data);
    });
  }, []);
  useEffect(() => {
    API.get(`/offers?d1=${date[0]}&d2=${date[1]}`).then((res) => {
      setOffers(res.data);
    });
  }, [date]);
  const cellWidth =
    100 /
      (JSON.parse(localStorage.getItem("filters")).filter((item) => {
        return item === true ? 1 : 0;
      }).length +
        2) +
    "%";
  return (
    <Container style={{ zIndex: 101 }}>
      <div
        style={{
          width: "100%",
          float: "left",
          display: "flex",
          margin: "15px 0",
        }}
      >
        <DateRangePicker onChange={setDate} value={date} />
        <Select
          size="small"
          label="Group"
          style={{ marginLeft: "15px" }}
          value={group}
          onChange={(e) => setGroup(e.target.value)}
        >
          <MenuItem value="day">День</MenuItem>
          <MenuItem value="week">Неделя</MenuItem>
          <MenuItem value="month">Месяц</MenuItem>
        </Select>
        <FormControlLabel
          label="Колонки"
          labelPlacement="left"
          sx={{ ml: 2 }}
          control={
            <IconButton onClick={() => setOpenFilter(true)}>
              <FilterAltIcon />
            </IconButton>
          }
        ></FormControlLabel>
      </div>
      <TableContainer component={Paper}>
        <Table size="small">
          <caption></caption>
          <TableHead>
            <TableRow>
              <TableCell
                width={
                  (100 /
                    (JSON.parse(localStorage.getItem("filters")).filter(
                      (item) => {
                        return item === true ? 1 : 0;
                      }
                    ).length +
                      2)) *
                    2 +
                  "%"
                }
                align="left"
              >
                OFFER
              </TableCell>
              {filters[10] ? (
                <TableCell width={cellWidth}>Click</TableCell>
              ) : null}
              {filters[0] ? (
                <TableCell width={cellWidth}>Установки</TableCell>
              ) : null}
              {filters[1] ? (
                <TableCell width={cellWidth}>Покупки</TableCell>
              ) : null}
              {filters[2] ? (
                <TableCell width={cellWidth}>Регистрации</TableCell>
              ) : null}
              {filters[3] ? (
                <TableCell width={cellWidth}>Триалы</TableCell>
              ) : null}
              {filters[4] ? (
                <TableCell width={cellWidth}>Custom</TableCell>
              ) : null}
              {filters[5] ? <TableCell width={cellWidth}>CR%</TableCell> : null}
              {filters[9] ? (
                <TableCell width={cellWidth}>Revenue</TableCell>
              ) : null}

              {filters[6] ? <TableCell width={cellWidth}>0d</TableCell> : null}
              {filters[7] ? <TableCell width={cellWidth}>7d</TableCell> : null}
              {filters[8] ? <TableCell width={cellWidth}>30d</TableCell> : null}
              {/* <TableCell align="left">Cобытий</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {offers.map((offer) => {
              if (offer.offer !== null) {
                return (
                  <OfferRow
                    cellWidth={cellWidth}
                    key={offer.offer + offer.clicks + offer.installs}
                    data={offer}
                    date={date}
                  />
                );
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <FilterDialog open={openFilter} close={() => setOpenFilter(false)} />
    </Container>
  );
};

export default Main;
