import logo from "./logo.svg";
import "./App.css";
import Login from "./components/Login";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Main from "./components/Main";
import { useEffect, useState } from "react";
import API from "./utils/API";
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function App() {
  const [isAuth, setIsAuth] = useState(false);
  useEffect(() => {
    if (!localStorage.getItem("filters")) {
      localStorage.setItem(
        "filters",
        JSON.stringify([
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ])
      );
    }
    API.get("/checktoken").then((res) => {
      if (res.status === 200) {
        setIsAuth(true);
      }
    });
  }, []);
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div className="App">
        {isAuth ? <Main /> : <Login setAuth={() => setIsAuth(true)} />}
      </div>
    </ThemeProvider>
  );
}

export default App;
