import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import API from "../../utils/API";

const Login = ({ setAuth }) => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  function postAuth() {
    API.post("/login", { login, password }).then((res) => {
      if (res.status === 200) {
        setAuth();
      }
    });
  }
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: 500,
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto",
        }}
      >
        <TextField
          value={login}
          onChange={(e) => setLogin(e.target.value)}
          placeholder="login"
        />
        <TextField
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="password"
        />
        <Button onClick={() => postAuth()}>Submit</Button>
      </div>
    </div>
  );
};

export default Login;
